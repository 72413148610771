* {
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat&family=Poppins:wght@400;500;600;700;800&family=Poppins&display=swap");

#Slider__range {
  background: #455b6e;
  height: 2px;
}

#Slider__range .range-slider__range {
  background: #455b6e;
  transition: height 0.3s;
}

#Slider__range .range-slider__thumb {
  width: 22px;
  height: 22px;
  background: #1a7d62;
  border: 2px solid #fff;
  transition: transform 0.3s;
}

#Slider__range .range-slider__thumb[data-active] {
  transform: translate(-50%, -50%) scale(1.25);
}

#Slider__range .range-slider__range[data-active] {
  height: 2px;
}

#Slider__range__disabled {
  background: #d6dbde;
  height: 2px;
}

#Slider__range__disabled .range-slider__range {
  background: #d6dbde;
  transition: height 0.3s;
}

#Slider__range__disabled .range-slider__thumb {
  width: 22px;
  height: 22px;
  background: #d6dbde;
  border: 2px solid #fff;
  transition: transform 0.3s;
}

#Slider__range__disabled .range-slider__thumb[data-active] {
  transform: translate(-50%, -50%) scale(1.25);
}

#Slider__range__disabled .range-slider__range[data-active] {
  height: 2px;
}

body::-webkit-scrollbar {
  height: 7px;
  width: 7px;
}

body::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #eee;
}

body::-webkit-scrollbar-thumb {
  border-radius: 25px;
  background-color: #55bc55;
}

.WeekCalendar .rdrDefinedRangesWrapper {
  display: none;
}
.MonthCalendar .rdrDefinedRangesWrapper {
  display: none;
}
.QuarterCalendar .rdrDefinedRangesWrapper {
  display: none;
}

.modalSubmitButton {
  background-color: #ef233c !important;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s;

  &:hover {
    background-color: #bd1d2d !important;
  }
}

.modalCancelButton {
  background-color: transparent !important;
  color: #333 !important;
}

.react-datepicker__header__dropdown--select {
  margin-top: 1rem !important;
  /* background-color: red; */
}

.react-datepicker__month-select,
.react-datepicker__year-select {
  outline: none;
  border: none;
  padding: 0.3rem 0.2rem;
  border-radius: 8px;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--keyboard-selected {
  background-color: #007bff !important;
}

.react-datepicker__day--in-selecting-range {
  background-color: #007bff !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--keyboard-selected {
  color: white !important;
}

.react-datepicker__month-text {
  padding: 0.5rem 1rem !important;
}
